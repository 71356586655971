import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Hero from "./style";
import Video from "~components/VideoModal";

export default function HeroPanelSections({ ...rest }) {

  return (
    <Hero backgroundColor="#fff" {...rest}>

      <Hero.Title as="h2" fontColor="">
        Secciones del Panel
      </Hero.Title>
      <div style={{ padding: "3rem" }}>
        <Container>
          <Row>
            <Col xs={12} md={4} className="mb-4">
                <Hero.Video
                  className="welcome-img welcome-img--l9 video-box"
                  mb="30px"
                  mbLG="0"
                >
                  <img
                    src="https://i.ytimg.com/vi/ykduMGveunY/hqdefault.jpg?sqp=-oaymwFBCPYBEIoBSFryq4qpAzMIARUAAIhCGAHYAQHiAQoIGBACGAY4AUAB8AEB-AH-CYAClAWKAgwIABABGCcgUCh_MA8=&rs=AOn4CLDCUkTpdGyI5OLwnxZKa6zpgarS9A"
                    style={{ width: "100%", borderRadius: "25px" }}
                    alt="Sección Avalúos"
                  ></img>
                  {/* Video Button */}
                  <Video id="ykduMGveunY" className="video-btn">
                    <i className="fa fa-play" />
                  </Video>
                </Hero.Video>
                <Hero.Content>
                  <Hero.Subtitle as="h4" fontColor="rgb(80, 52, 252)">
                    Sección "Avalúos"
                  </Hero.Subtitle>
                  {/*<Hero.Text>
                    When, while lovely valley teems with vapour around
                    <br className="d-none d-xs-block" /> meand meridian sun
                    strikes the upper impenetroble
                    <br className="d-none d-xs-block" /> foliage of my trees,
                    and but a thousand.
                  </Hero.Text>*/}
                  <Hero.Text>
                  Asigna un valor de mercado a los artículos disponibles. Consulta el historial de precios para ajustar tus ofertas de manera más competitiva.
                  </Hero.Text>
                </Hero.Content>
            </Col>
          </Row>
        </Container>
      </div>
    </Hero>
  );
}
